import { useRootStore } from "../../../hooks/useRootStore"
import { useState, useRef} from 'react';
import { useForm, Controller } from "react-hook-form"
import { styled } from "@mui/system"
import { Button, Box, TextField, Typography, Stack } from "@mui/material"

import FacebookIcon from "@mui/icons-material/Facebook"
import GoogleIcon from "@mui/icons-material/Google"
import { getGoogleOAuthURL } from "../../../utility"
import { useLoading } from "../../../hooks/useLoading"
import { useNotification } from "../../../hooks/useNotification"



const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  maxHeight: "50%",
}))

const StyledFormContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxHeight: "75%",
  overflow: "hidden",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
}))

const StyledActionContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: theme.spacing(1),
}))

const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    // background: "black",
    // input: { color: "white" },
    // label: { color: "white" },
  }
})





export const Login = props => {
  const [visiblePassword, setVisiblePassword] = useState("")
  const latestPasswordRef = useRef("");
  const timeoutRef = useRef(null)
  const { onSuccess } = props

  const { configStore } = useRootStore()
  const { control, handleSubmit, watch } = useForm()
  const { toggleLoading } = useLoading()
  const { addMessage } = useNotification()

  const email = watch("email")
  const pass = watch("password")

  const useRouterClient =
    typeof window !== "undefined" ? require("next/router").useRouter : null
  const router = useRouterClient ? useRouterClient() : null

  const onSubmit = data => {
    toggleLoading(true, "Signing you in")
    configStore
      .login({
        email: data.email,
        password: data.password,
      })
      .then(resp => {
        toggleLoading(false, "")
        if (!resp.error) {
          configStore.updateUser(resp.user)

          onSuccess(resp.user.accountType)
        } else {
          addMessage(resp.message, "error")
        }
      })
  }

  const googleAuth = () => {
    window.location.href = getGoogleOAuthURL()
  }

  return (
    <Stack>
      <Typography align='center' variant='h6' sx={{mb:2}}>Welcome Back!</Typography>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormContainer>
          <Controller
            name='email'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) => (
              <StyledTextField
                {...field}
                id='Email Address'
                label='Email Address'
                placeholder='Enter your email address'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='outlined'
                size={"small"}
                value={field.value}
              />
            )}
          />
          <Controller
            name='password'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) => (
              <StyledTextField
                {...field}
                value={visiblePassword}
                id='Password'
                label='Password'
                placeholder='Enter your password'
                InputLabelProps={{
                  shrink: true,
                }}
                type='text'
                variant='outlined'
                size={"small"}
                onChange={(e)=>{
                  
                  const newPassword = e.target.value
        
                  // Update the actual password in the form
                  field.onChange(newPassword)
                  
                   // Store the latest password
                  latestPasswordRef.current = newPassword

                  // Show the newly typed password
                  setVisiblePassword(newPassword)
            
                  
                  // Clear any existing timeout
                  if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                  }  
                  
                  // Set new timeout - hide the password after 2 seconds
                  timeoutRef.current = setTimeout(() => {
                    setVisiblePassword('•'.repeat(latestPasswordRef.current.length))
                  }, 750)
                }}
              />
            )}
          />
        </StyledFormContainer>
        <StyledActionContainer>
          <Button
            disabled={!pass || !email}
            color='secondary'
            variant='outlined'
            type='submit'
          >
            Sign In
          </Button>
        </StyledActionContainer>
      </StyledForm>
      {/* <Typography>Or Continue With</Typography> */}
      <Box mx="auto">
        <Button
          disabled={true}
          onClick={() => {
            googleAuth()
          }}
        >
          <GoogleIcon />
          Google
        </Button>
        <Button disabled={true}>
          <FacebookIcon />
          Facebook
        </Button>
      </Box>
    </Stack>
  )
}