import React, { useState, useMemo, useRef, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { styled } from "@mui/system"
import {
  Button,
  Box,
  TextField,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material"
import { useRootStore } from "../../../hooks/useRootStore"
import { useNotification } from "../../../hooks/useNotification"
import { useLoading } from "../../../hooks/useLoading"

import FacebookIcon from "@mui/icons-material/Facebook"
import GoogleIcon from "@mui/icons-material/Google"
import { getGoogleOAuthURL } from "../../../utility"
import mixpanel from "mixpanel-browser"

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  maxHeight: "50%",
}))

const StyledFormContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxHeight: "75%",
  overflow: "hidden",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
}))

const StyledActionContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: theme.spacing(1),
}))

const StyledTextField = styled(TextField)(({ theme }) => {
  return {}
})

export const Register = props => {
  const { onSuccess, accountType, referralId } = props
  const [registerType, setRegisterType] = useState("native")

  /**
   * Track when Registration starts
   */
  useEffect(() => {
    mixpanel.track("signup started")
  }, [])

  const registerView = useMemo(() => {
    switch (registerType) {
      case "native":
        return (
          <NativeRegistration
            referralId={referralId}
            accountType={accountType}
            onSuccess={onSuccess}
          />
        )
      case "google":
        return (
          <GoogleRegistration accountType={accountType} onSuccess={onSuccess} />
        )
      default:
        return null
    }
  }, [registerType])

  return (
    <Stack gap={1}>
      <Typography variant='h6'>{accountType.charAt(0).toUpperCase() + accountType.slice(1)} Registration</Typography>
      {registerView}
      {registerType === "native" ? (
        <>
          <Box sx={{ display:'flex', flexDirection:"row", alignItems:"center"}}>
            <Box sx={{mx:'auto'}}>
              <Button
                disabled={true}
                onClick={() => {
                  setRegisterType("google")
                }}
              >
                <GoogleIcon />
                Google
              </Button>
              <Button disabled={true}>
                <FacebookIcon />
                Facebook
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              setRegisterType("native")
            }}
          >
            Bact to Native Registration
          </Button>
        </>
      )}
    </Stack>
  )
}

const NativeRegistration = ({ accountType, referralId, onSuccess }) => {
  const { configStore } = useRootStore()
  const { addMessage } = useNotification()
  const { toggleLoading } = useLoading()

  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      referral: referralId || "",
      account_type: accountType || "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      confirm_pass: "",
    },
  })

  const validatePasswordMatch = value => {
    const password = getValues("password")
    return value === password || "Passwords do not match"
  }

  const ErrorScroll = useMemo(() => {
    if (Object.keys(formState.errors).length > 0) {
      return <ScrollToError errors={formState.errors} />
    } else return <></>
  }, [Object.keys(formState.errors).length])

  /**
   * REGISTER SUBMISSION
   */
  const onError = e => {
    addMessage(
      "Please complete and fix all errors above before registering",
      "error",
    )
  }

  const onSubmit = data => {
    debugger
    toggleLoading(true, "Registering")
    configStore.register(data).then(resp => {
      toggleLoading(false, "")
      if (!resp.error) {
        const user = {
          loggedIn: true,
          user_type: resp.user.accountType, // reviewer || artist
          roles: [resp.user.accountType],
          user_id: resp.user.id,
        }

        configStore.updateUser(user)

        mixpanel.track("signup completed", {
          uniqueUserId: resp.id,
          registrationMethod: "NATIVE",
          registrationDate: new Date(),
          referredSource: "",
          referralCode: "",
        })

        onSuccess(resp.user.accountType)
      } else {
        addMessage(resp.message, "error")
      }
    })
  }

  const [visiblePassword, setVisiblePassword] = useState("")
  const [confVisiblePassword, setConfVisiblePassword] = useState("")
  const latestPasswordRef = useRef("");
  const timeoutRef = useRef(null)

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit, onError)}>
      <StyledFormContainer>
        <Controller
          name='first_name'
          control={control}
          defaultValue=''
          rules={{ required: true }}
          render={({ field }) => (
            <StyledTextField
              id='First Name'
              label='First Name'
              variant='outlined'
              placeholder='Enter your first name'
              value={field.value}
              error={formState.errors.first_name}
              className={formState.errors.first_name ? "error-field" : ""}
              onChange={event => {
                return field.onChange(event.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        <Controller
          name='last_name'
          control={control}
          defaultValue=''
          rules={{ required: true }}
          render={({ field }) => (
            <StyledTextField
              id='Last Name'
              label='Last Name'
              variant='outlined'
              placeholder='Enter your last name'
              value={field.value}
              error={formState.errors.last_name}
              className={formState.errors.last_name ? "error-field" : ""}
              onChange={event => {
                return field.onChange(event.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />

        <Controller
          id={"email"}
          name='email'
          control={control}
          defaultValue=''
          rules={{ required: true }}
          render={({ field }) => (
            <StyledTextField
              variant='outlined'
              id='Email Address'
              label='Email Address'
              placeholder='Enter your email address'
              value={field.value}
              error={formState.errors.email}
              className={formState.errors.email ? "error-field" : ""}
              onChange={event => {
                return field.onChange(event.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        <Controller
          name='phone_number'
          control={control}
          defaultValue=''
          rules={{ required: true }}
          render={({ field }) => (
            <StyledTextField
              id='Phone Number'
              variant='outlined'
              label='Phone Number'
              placeholder='Enter your phone number'
              value={field.value}
              error={formState.errors.phone_number}
              className={formState.errors.phone_number ? "error-field" : ""}
              onChange={event => {
                return field.onChange(event.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        <Controller
            name='password'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) => (
              <StyledTextField
                {...field}
                value={visiblePassword}
                id='Password'
                label='Password'
                placeholder='Enter your password'
                InputLabelProps={{
                  shrink: true,
                }}
                type='text'
                variant='outlined'
                size={"small"}
                onChange={(e)=>{
                  
                  const newPassword = e.target.value
        
                  // Update the actual password in the form
                  field.onChange(newPassword)
                  
                   // Store the latest password
                  latestPasswordRef.current = newPassword

                  // Show the newly typed password
                  setVisiblePassword(newPassword)
                  
                  
                  // Clear any existing timeout
                  if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                  }  
                  
                  // Set new timeout - hide the password after 2 seconds
                  timeoutRef.current = setTimeout(() => {
                    setVisiblePassword('•'.repeat(latestPasswordRef.current.length))
                  }, 750)
                }}
              />
            )}
          />
        {/* <Controller
          name='confirm_pass'
          control={control}
          defaultValue=''
          rules={{ required: true, validate: validatePasswordMatch }}
          render={({ field }) => (
            <StyledTextField
              type='password'
              variant='outlined'
              id='Confirm Password'
              label='Confirm Password'
              placeholder='Confirm your password'
              value={field.value}
              error={formState.errors.confirm_pass}
              className={formState.errors.confirm_pass ? "error-field" : ""}
              onChange={event => {
                return field.onChange(event.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        /> */}

        <Controller
            name='confirm_pass'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) => (
              <StyledTextField
                {...field}
                value={confVisiblePassword}
                id='Confirm Password'
                label='Confirm Password'
                placeholder='Confirm your password'
                InputLabelProps={{
                  shrink: true,
                }}
                type='text'
                variant='outlined'
                size={"small"}
                onChange={(e)=>{
                  
                  const newPassword = e.target.value
        
                  // Update the actual password in the form
                  field.onChange(newPassword)
                  
                   // Store the latest password
                  latestPasswordRef.current = newPassword

                  // Show the newly typed password
                  setConfVisiblePassword(newPassword)
                  
                  // Clear any existing timeout
                  if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                  }  
                  
                  // Set new timeout - hide the password after 2 seconds
                  timeoutRef.current = setTimeout(() => {
                    setConfVisiblePassword('•'.repeat(latestPasswordRef.current.length))
                  }, 750)
                }}
              />
            )}
          />



      </StyledFormContainer>
      <StyledActionContainer>
        <Button color='secondary' variant='outlined' type='submit'>
          Sign Up
        </Button>
      </StyledActionContainer>
      {/* Scroll logic */}
      {ErrorScroll}
    </StyledForm>
  )
}

const GoogleRegistration = ({ accountType, onSuccess }) => {
  const { configStore } = useRootStore()
  const { addMessage } = useNotification()

  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      account_type: accountType || "",
    },
  })

  /**
   * REGISTER SUBMISSION
   */
  const onError = e => {
    addMessage(
      "Please complete and fix all errors above before registering",
      "error",
    )
  }

  const onSubmit = data => {
    window.location.href = getGoogleOAuthURL(data.account_type)
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit, onError)}>
      <Controller
        id='account_type'
        name='account_type'
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            select
            size='small'
            id='Account Type'
            variant='outlined'
            label='Account Type'
            value={field.value}
            error={formState.errors.account_type}
            onChange={event => {
              return field.onChange(event.target.value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value='artist'>Artist</MenuItem>
            <MenuItem value='reviewer'>Listener</MenuItem>
          </TextField>
        )}
      />
      <StyledActionContainer>
        <Button color='secondary' variant='outlined' type='submit'>
          Register with Google
        </Button>
      </StyledActionContainer>
    </StyledForm>
  )
}

const ScrollToError = props => {
  const { errors } = props

  // Function to scroll to the first error field
  const scrollToError = () => {
    const firstErrorField = document.querySelector(".error-field")
    if (firstErrorField) {
      firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  // Scroll to the first error field when component mounts
  useEffect(() => {
    scrollToError()
  }, [Object.keys(errors).length])

  return null
}

export default Register