import React from "react"
import { Box, Typography } from "@mui/material"
import { motion } from "framer-motion"
import { styled } from "@mui/material/styles"

const CrawlContainer = styled(Box)({
  display: "flex",
  height: "50vh",
  width: "100%",
  overflowY: "hidden", // Prevent vertical overflow
  perspective: "600px",
  alignItems: "center",
  justifyContent: "center",
})

const TextCrawl = styled(motion.div)(({ theme }) => {
  return {
    position: "absolute",
    top: "100%",
    width: "60%",
    transform: "translateX(-50%)",
    fontSize: "100%",
    fontWeight: "bold",
    textAlign: "justify",
    alignItems: "center",
    display: "flex", // Ensure flex display
    flexDirection: "column", // Stack items vertically
  }
})

export const CrawlText = props => {
  const { onScrollEnd } = props

  return (
    <CrawlContainer>
      <TextCrawl
        initial={{ rotateX: 25, y: 0 }}
        animate={{ y: "-135%" }}
        transition={{ duration: 80, ease: "linear" }}
        onAnimationComplete={onScrollEnd}
      >
        <Typography
          variant='h3'
          align='center'
          fontWeight={"bold"}
          color='secondary'
          gutterBottom
          sx={{ fontSize: { xs: "2rem", sm: "3rem" } }}
        >
          CLUTCH AUDIENCE
        </Typography>
        <Typography
          variant='h6'
          align='center'
          fontWeight={"bold"}
          color='secondary'
        >
          About Me
        </Typography>
        <Typography
          paragraph
          align='center'
          fontWeight={"bold"}
          color='secondary'
        >
          Are you an artist struggling to connect with your target audience? Do
          you find it challenging to get genuine feedback from the listeners who
          matter most? Look no further! Welcome to a platform designed
          specifically for you. Where artists and music lovers unite to create a
          vibrant community of creativity and constructive critique.
        </Typography>
        <Typography
          paragraph
          align='center'
          fontWeight={"bold"}
          color='secondary'
          variant='h6'
        >
          For Artists
        </Typography>
        <Typography
          paragraph
          align='center'
          fontWeight={"bold"}
          color='secondary'
        >
          As an artist, your journey is fueled by passion, but navigating the
          music industry can be overwhelming. You need real insights from the
          right people—those who resonate with your sound and vision. Here,
          you'll find a dedicated audience eager to engage with your work,
          provide valuable feedback, and help you refine your craft. Our
          community is built on collaboration and support, ensuring that your
          art reaches its full potential.
        </Typography>
        <Typography
          paragraph
          align='center'
          fontWeight={"bold"}
          color='secondary'
          variant='h6'
        >
          For Music Lovers
        </Typography>
        <Typography
          paragraph
          align='center'
          fontWeight={"bold"}
          color='secondary'
        >
          Are you an avid music enthusiast with a keen ear for talent? Do you
          believe that your insights can help artists elevate their music? This
          is your chance to make a difference! Join our platform to share your
          thoughts, critique emerging artists, and contribute to the creative
          process. Your feedback could be the key that unlocks an artist's true
          potential.
        </Typography>
        <Typography
          paragraph
          align='center'
          fontWeight={"bold"}
          color='secondary'
          variant='h6'
        >
          Join Us Today!
        </Typography>
      </TextCrawl>
    </CrawlContainer>
  )
}