import { useContext } from "react";
import { LoadingContext } from "../context/LoadingContext";

export const useLoading = () =>  {
    const context = useContext(LoadingContext);

    if (context === undefined) {
        console.error('useLoading must be used within Loading Context Provider')
    }

    return context
}