import { BASE_URL } from './store/config.store';
export const capitalizeFirstLetter = (inputString) => {
  if (typeof inputString !== 'string' || !inputString) {
    return '';  
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};

const REDIRECT_URL = process.env.NEXT_PUBLIC_GOOGLE_REDIRECT_URL;

/**
 * NEEDS TO UPDATE AND POINT TO ENV
 */
export const getGoogleOAuthURL = (accountType) => {
  const rootURL = 'https://accounts.google.com/o/oauth2/v2/auth';

  const options = {
    redirect_uri: REDIRECT_URL,
    client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
    ].join(' '),
    state: JSON.stringify({
      accountType: accountType,
    }),
  };

  console.log('google oauth opts', options)

  const qs = new URLSearchParams(options);

  return `${rootURL}?${qs.toString()}`;
};

// returns number of seconds in '0:00' format or '8s' format
export const secondsToTS = (seconds) => {
  let roundSec = Math.floor(seconds);
  let mins;
  let secs;
  if (roundSec < 60) {
    return `${roundSec}s`;
  } else {
    mins = Math.floor(roundSec / 60);
    secs = roundSec % (60 * mins);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  }
};

export const transformArray = (data) => {
  if (Array.isArray(data) && data?.length) {
    // Capitalize the first letter of each element
    const transformedArray = data.map((item) => capitalizeFirstLetter(item));

    return transformedArray.join(', ');
  } else if (typeof data === 'object') {
    // Convert object to an array of key-value pairs and format each pair as "key=value"
    const keyValuePairs = Object.entries(data).map(
      ([key, value]) => `${key}=${value}`
    );
    return keyValuePairs.join(', ');
  }
  return '';
};
