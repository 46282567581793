import { useState } from "react"
import { Button, Stack, Typography, TextField } from "@mui/material"
import { useNotification } from "../../../hooks/useNotification"
import axios from "axios"
import { useLoading } from "../../../hooks/useLoading"

const BASEURL = process.env.NEXT_PUBLIC_API_URL || "https://clutchaudience.com"

export const ForgotPassword = props => {
  const { addMessage } = useNotification()
  const [email, setEmail] = useState("")
  const { toggleLoading } = useLoading()

  const resetPassword = async () => {
    toggleLoading(true, "Attempting to find email and send reset password link")

    try {
      const api = axios.create({
        baseURL: BASEURL,
      })

      const response = await api.post(
        `/api/users/request-password-reset`,
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      toggleLoading(false, "")

      if (!response.data.errors && !response.data.error) {
        addMessage(response.message, "success")
      } else {
        addMessage(`Unable to send reset password email`, "error")
      }
    } catch {
      toggleLoading(false, "")
      addMessage(`Unable to send reset password email`, "error")
    }
  }

  return (
    <Stack gap={2}>
      <Typography variant={"body1"}>
        Please enter your email to search for your account and send email for
        password reset
      </Typography>
      <TextField
        label={"Email"}
        size={"small"}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Button
        disabled={!email}
        color={"secondary"}
        variant={"outlined"}
        onClick={() => {
          resetPassword()
        }}
      >
        Send Reset Link
      </Button>
    </Stack>
  )
}
