import { useContext } from "react";
import { NotificationContext } from "../context/NotificationContext";

export const useNotification = () =>  {
    const context = useContext(NotificationContext);

    if (context === undefined) {
        console.error('useNotification must be used within Notification Context Provider')
    }

    return context
}