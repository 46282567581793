import React, { useEffect, useState } from "react"
import { Box, Button, Typography, keyframes, Link, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import Starfield from "react-starfield"
import { CrawlText } from "../../components-clean/shared/CrawlText"
import { useRouter } from "next/router"
import {
  ForgotPassword,
  Login,
  Register,
} from "../../components-clean/shared/auth"

import dynamic from "next/dynamic"

const MotionBox = dynamic(
  () => import("framer-motion").then(mod => mod.motion.div),
  { ssr: false },
)
const AnimatePresence = dynamic(
  () => import("framer-motion").then(mod => mod.AnimatePresence),
  { ssr: false },
)

// Comic-style animation variants
const comicVariants = {
  initial: {
    opacity: 0,
    scale: 0.5,
    rotate: -20,
  },
  animate: {
    opacity: 1,
    scale: 1,
    rotate: 0,
    transition: {
      type: "spring",
      stiffness: 260,
      damping: 20,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    rotate: 20,
  },
}

const wiggleVariants = {
  wiggle: {
    rotate: [-1, 1, -1],
    transition: {
      duration: 0.5,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut",
    },
  },
}

// Styled Comic Bubble
const ComicBubble = styled(MotionBox)(({ theme }) => {
  const pulse = keyframes`
  0% {
    border-color: transparent;
  }
  50% {
    border-color: ${theme.palette.primary.main}60;
  }
  100% {
    border-color: transparent;
  }
`
  return {
    position: "relative",
    backgroundColor: "transparent",
    textAlign: "center",
  }
})

function ClientOnly({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) return null
  return <div {...delegated}>{children}</div>
}

/**
 * INTERACTIVE LOGIN/REGISTER
 *
 */
const LoginForm = () => {
  const router = useRouter()

  if (!router.isReady) {
    return <div>Loading...</div>
  }

  const { accountType, referralId } = router.query

  const [stage, setStage] = useState(0)
  const [userType, setUserType] = useState("")
  const [isReturning, setIsReturning] = useState(null)
  const [readAbout, setReadAbout] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(null)

  /** */
  useEffect(() => {
    if (accountType) {
      setIsReturning(false)
      setUserType(accountType)
      setStage(2)
    }

    if (referralId) {
      setIsReturning(false)
      setStage(1)
    }
  }, [referralId, accountType])

  const registerSuccess = accountType => {
    setStage(3)

    // Delay the routing logic by 4 seconds (4000 milliseconds)
    setTimeout(() => {
      if (accountType === "artist") {
        router.push("/workspace")
      }

      else if (accountType === "reviewer") {
        router.push("/marketplace")
      } 
      
      else if (accountType === "affiliate") {
        router.push("/affiliate")
      }
    }, 4000) // 4000 milliseconds = 4 seconds
  }

  const loginSuccess = async accountType => {
    if (!accountType) return

    setStage(3)

    // Delay the routing logic by 4 seconds (4000 milliseconds)
    setTimeout(() => {
      if (accountType === "artist") {
        router.push("/workspace")
      } else if (accountType === "reviewer") {
        router.push("/marketplace")
      } else if (accountType === "admin") {
        router.push("/admin")
      } else if (accountType === "affiliate") {
        router.push("/affiliate")
      } else {
        window.alert("unable to determine user role")
      }
    }, 4000) // 4000 milliseconds = 4 seconds
  }

  /**
   * Stages for Interactive login
   */
  const stages = [
    {
      content: (
        <ComicBubble
          variants={{ ...comicVariants, ...wiggleVariants }}
          initial='initial'
          animate={["animate", "wiggle"]}
          exit='exit'
        >
          <Typography variant='h6'>Are you returning?</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth:"1000px",
              gap: 2,
              mt: 2,
              mb: 2,
              mx:'auto'
            }}
          >
            <Button
              variant='outlined'
              color='secondary'
              size='large'
              onClick={() => {
                setIsReturning(true)
                setStage(2) // Skip to login stage
              }}
              fullWidth
            >
              Yes, I'm returning
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              size='large'
              onClick={() => {
                setIsReturning(false)
                setStage(1) // Go to user type selection
              }}
              fullWidth
            >
              No, I'm new here
            </Button>
          </Box>
          <Button
            variant='text'
            color='secondary'
            size='small'
            onClick={() => {
              setReadAbout(!readAbout)
            }}
          >
            {readAbout ? "go back" : "read about us"}
          </Button>
        </ComicBubble>
      ),
    },
    {
      content: (
        <ComicBubble
          variants={{ ...comicVariants, ...wiggleVariants }}
          initial='initial'
          animate={["animate", "wiggle"]}
          exit='exit'
        >
          <Typography variant='h6'>Are you an Artist or Listener?</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth:'1000px',
              gap: 2,
              mt: 2,
              mb: 2,
              mx:'auto'
            }}
          >
            <Button
              variant='outlined'
              color='secondary'
              size='large'
              onClick={() => {
                setUserType("artist")
                setStage(2)
              }}
              fullWidth
            >
              Artist
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              size='large'
              onClick={() => {
                setUserType("reviewer")
                setStage(2)
              }}
              fullWidth
            >
              Listener
            </Button>
          </Box>
          <Button
            variant='text'
            color='secondary'
            size='small'
            onClick={() => {
              setReadAbout(!readAbout)
            }}
          >
            {readAbout ? "go back" : "read about us"}
          </Button>
        </ComicBubble>
      ),
    },
    {
      content: (
        <ComicBubble>
          {isReturning ? (
            <>
              {forgotPassword ? (
                <ForgotPassword />
              ) : (
                <Login onSuccess={loginSuccess} />
              )}
            </>
          ) : (
            <Register
              accountType={userType}
              referralId={referralId}
              onSuccess={registerSuccess}
            />
          )}
          <Stack align="center" sx={{ mt: 2, mb: 2}} gap={1}>
            {isReturning && !forgotPassword ? (
              <Typography variant={"caption"} >
                <Button
                  color={"secondary"}
                  onClick={() => setForgotPassword(true)}
                >
                  Forgot Password?
                </Button>
              </Typography>
            ) : null}

            <Typography variant='body2'>
              {isReturning
                ? "Don't have an account? "
                : "Already have an account? "}
              <Link
                component='button'
                variant='body2'
                color={"secondary"}
                onClick={() => {
                  setForgotPassword(false)
                  setIsReturning(!isReturning)
                  if (isReturning) {
                    setStage(1) // Go to user type selection if switching to register
                  }
                  // If switching to login, we stay on the current stage
                }}
              >
                {isReturning ? "Register here" : "Login here"}
              </Link>
            </Typography>

            <Button
              variant='text'
              color='secondary'
              size='small'
              onClick={() => {
                setReadAbout(!readAbout)
              }}
            >
              {readAbout ? "go back" : "read about us"}
            </Button>
          </Stack>
        </ComicBubble>
      ),
    },
    {
      content: (
        <ComicBubble
          variants={comicVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <Typography variant='h6'>
            {isReturning
              ? "Welcome back, Enjoy your session!"
              : `Welcome, ${userType}. Check your email for confirmation!`}
          </Typography>
        </ComicBubble>
      ),
    },
  ]

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        paddingX: { xs: 2, sm: 4 },
        paddingY: { xs: 2, sm: 4 },
      }}
    >
      {/* Background */}
      <Starfield
        starCount={10000}
        starColor={[255, 232, 31]}
        speedFactor={0.09}
        backgroundColor='black'
      />

      <div> </div>
      <ClientOnly style={{ width: "100%" }}>
        <AnimatePresence mode='wait'>
          <Box key={stage} sx={{ width: "100%", position: "relative"}}>
            {readAbout ? (
              <CrawlText onScrollEnd={() => setReadAbout(false)} />
            ) : (
              stages[stage].content
            )}
          </Box>
        </AnimatePresence>
      </ClientOnly>
      <div></div>
    </Box>
  )
}

export default LoginForm
